@mixin extraSmallScreen {
  @media screen and (max-width: 380px) {
    @content;
  }
}

@mixin smallScreen {
  @media screen and (max-width: 800px) {
    @content;
  }
}
